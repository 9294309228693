<template>
  <b-nav-dropdown menu-class="menu dropdown-user-actions" right variant="text" :no-caret="true">
    <template v-slot:button-content>
      <div class="has-text-icon">
        <span class="icon-circle bg-info top-nav-has-new-messages" :title="$tc('user.menu.new_messages', messageCount)" v-if="messageCount">
          <font-awesome-icon icon="user"/>
        </span>
        <span class="icon-circle bg-info" v-else>
          <font-awesome-icon icon="user"/>
        </span>
        <span class="user-menu-info">
          {{ userDisplayName }}
          <font-awesome-icon class="icon" icon="chevron-down"/>
        </span>
      </div>
    </template>
    <b-dropdown-item :disabled="true" v-if="isCompanyAccount">
      <span class="text-ellipsis">{{ activeAccount.displayName }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'dashboard' }">
      <span class="icon-wrap"><font-awesome-icon icon="tachometer-alt"/></span>
      {{ $t('components.partials.header.nav.dashboard') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'messages' }">
      <span class="icon-wrap has-new-messages" :title="$tc('user.menu.new_messages', messageCount)" v-if="messageCount">
        <font-awesome-icon icon="envelope"/>
      </span>
      <span class="icon-wrap" v-else>
        <font-awesome-icon icon="envelope"/>
      </span>
      {{ $t('components.partials.header.nav.messages') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'profile' }" v-if="isApplicantAccount">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.partials.header.nav.profile_applicant') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'owncompanyprofile' }" v-if="isCompanyAccount && isGranted(permissions.COMPANY_EDIT_PROFILE)">
      <span class="icon-wrap"><font-awesome-icon icon="user"/></span>
      {{ $t('components.partials.header.nav.profile_company') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'companybookmarks' }" v-if="enableSearchForCompanies && isApplicantAccount">
      <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
      {{ $t('components.partials.header.nav.bookmarks_applicant') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'applicantbookmarks' }" v-else-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="bookmark"/></span>
      {{ $t('components.partials.header.nav.bookmarks_company') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'applicantsearchprofiles' }" v-if="isCompanyAccount">
      <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
      {{ $t('components.partials.header.nav.search_profiles') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'companysearchprofiles' }" v-else-if="enableSearchForCompanies && isApplicantAccount">
      <span class="icon-wrap"><font-awesome-icon icon="search"/></span>
      {{ $t('components.partials.header.nav.search_profiles') }}
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'account' }">
      <span class="icon-wrap"><font-awesome-icon icon="address-card"/></span>
      {{ $t('components.partials.header.nav.account') }}
    </b-dropdown-item>
  </b-nav-dropdown>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ShowMessageCountMixin from '@/mixins/showMessageCountMixin';
  import permissions from '@/services/permissions';
  import Config from '@/services/config';

  export default {
    mixins: [ShowMessageCountMixin],
    computed: {
      ...mapGetters(['activeAccount', 'isCompanyAccount', 'isApplicantAccount', 'isGranted']),
      permissions: () => permissions,
      enableSearchForCompanies: function () {
        return !!Config.enableSearchForCompanies;
      },
      userDisplayName: function () {
        let displayName = '...';
        if (this.activeAccount && typeof this.activeAccount.firstName !== "undefined") {
          displayName = this.activeAccount.firstName + ' ' + this.activeAccount.lastName;
        }
        return displayName;
      },
    },
  };
</script>
<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .dropdown-user-actions {
    background: $ba-light-primary;
    font-size: $ba-font-size-sm;
    text-transform: uppercase;
    color: #fff;
    padding: 5px 0 6px 0;
    width: 100%;
    .dropdown-item {
      display: block;
      background: transparent;
      color: $ba-link-color-light;
      padding: 6px 20px 7px;
      transition: color .2s linear;
      @include hover-focus {
        color: #fff;
        background: transparent;
      }
    }
    svg {
      display: inline-block;
      margin-right: 14px;
    }
    .icon-wrap {
      display: inline-block;
      width: 26px;
      svg {
        margin-right: 0;
      }
    }
    .has-new-messages {
      @include ba-circle-bg($ba-red, 6px, 1px, 10px);
    }
  }
  .top-nav-has-new-messages {
    @include ba-circle-bg($ba-red, 6px, 0, -2px);
  }
  .user-menu-info {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      .icon {
        margin-left: 12px;
        margin-top: -4px;
      }
    }
  }
  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
</style>

<i18n src="@/mixins/showMessageCountMixin.i18n.yaml" lang="yaml"/>

<i18n lang="yaml" locale="de">
  components.partials.header.nav.dashboard: 'Dashboard'
  components.partials.header.nav.messages: 'Nachrichten'
  components.partials.header.nav.bookmarks_company: 'Bewerber*innen'
  components.partials.header.nav.bookmarks_applicant: 'Merkliste'
  components.partials.header.nav.search_profiles: 'Suchaufträge'
  components.partials.header.nav.profile_company: 'Profil'
  components.partials.header.nav.profile_applicant: 'Bewerbungsprofil'
  components.partials.header.nav.account: 'Account'
</i18n>
