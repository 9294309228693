import Vue from 'vue';
// eslint-disable-next-line
import { IconDefinition, library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

import {
  faInfo,
  faInfoCircle,
  faAddressCard,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faDownload,
  faBookmark,
  faBolt,
  faCalculator as fasCalculator,
  faEyeSlash,
  faSmile as fasSmile,
  faMeh as fasMeh,
  faFrown as fasFrown,
  faMehBlank as fasMehBlank,
  faCheck as fasCheck,
  faQuestionCircle as fasQuestionCircle,
  faQuestion,
  faTimes,
  faTimesCircle,
  faUser,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faUserPlus,
  faUserCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faList,
  faFolderOpen,
  faPlay,
  faPlayCircle,
  faSearchPlus,
  faPhone,
  faPhoneAlt,
  faFax,
  faMapPin,
  faGlobe,
  faCalculator,
  faChartBar,
  faSignOutAlt,
  faBars,
  faBuilding,
  faLink,
  faCalendarCheck,
  faIdCard,
  faSearch,
  faMapMarkerAlt,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPaperclip,
  faBook,
  faTrash,
  faUpload,
  faTachometerAlt,
  faEdit,
  faTasks,
  faUserCog,
  faBriefcase,
  faGraduationCap,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCheckCircle,
  faFile as farFile,
  faFilePdf as farFilePdf,
  faFileArchive as farFileArchive,
  faBuilding as farBuilding,
  faFrown,
  faSmile as farSmile,
  faMeh as farMeh,
  faMehBlank as farMehBlank,
  faSave as farSave,
} from '@fortawesome/free-regular-svg-icons';

import {
  faFacebookSquare,
  faInstagramSquare,
  faXingSquare,
  faLinkedin,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
  faAddressCard,
  faDownload,
  faBolt,
  faGlobe,
  faBookmark,
  faArrowRight,
  faCalculator,
  faSignOutAlt,
  faBars,
  faBook,
  faChartBar,
  faArrowLeft,
  faEnvelopeOpen,
  faBuilding,
  faArrowUp,
  faUserPlus,
  faUser,
  faEyeSlash,
  faInfo,
  faInfoCircle,
  faPlay,
  faPlayCircle,
  faSearchPlus,
  faFolderOpen,
  faLink,
  faEnvelope,
  faPhone,
  faPhoneAlt,
  faFax,
  faMapPin,
  faEye,
  faCheckCircle,
  faFrown,
  fasFrown,
  farSmile,
  fasSmile,
  farMeh,
  fasMeh,
  farMehBlank,
  fasMehBlank,
  faList,
  faUserCircle,
  faChevronDown,
  fasCalculator,
  fasCheck,
  fasQuestionCircle,
  faFacebookSquare,
  faInstagramSquare,
  faXingSquare,
  faLinkedin,
  faYoutubeSquare,
  faTimes,
  faTimesCircle,
  faChevronLeft,
  faChevronRight,
  faQuestion,
  farFile,
  farFileArchive,
  farFilePdf,
  faCalendarCheck,
  faIdCard,
  faSearch,
  faMapMarkerAlt,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPaperclip,
  faTrash,
  faUpload,
  faTachometerAlt,
  faEdit,
  farSave,
  faTasks,
  faUserCog,
  farBuilding,
  faBriefcase,
  faGraduationCap,
  faPencilAlt,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
